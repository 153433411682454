import { Typography } from "@mui/material";
import { StyledCentered, StyledProjects } from "../components/styledComponents/Styled";
import Typewriter from 'typewriter-effect';
import Grid from '@mui/material/Grid';
import ProjectItem from '../components/ProjectItem';

import kanbanImg from '../images/kanban.jpeg';
import shopImg from '../images/basic-shop.jpeg';
import calcImg from '../images/calc.jpeg';
import DrumMachineImg from '../images/drum-machine.jpeg';
import D3Img from '../images/D3.jpeg';
import PomodoroImg from '../images/pomodoro.jpeg';
import ThreeTestImg from '../images/three-test.jpeg';
import RandomQuotesImg from '../images/random-quotes.jpeg';
import RayhanImg from '../images/rayhan.jpeg';

export default function Portfolio() {
    const projects = [
        {title: 'Food Delivery App', description: 'Tech Stack: Express, NodeJS, MySQL, React, Redux, PWA, Sass', image: RayhanImg, link: 'https://rayhan24.ru'},
        {title: 'Kanban Dashboard', description: 'Tech Stack: React, Redux, PWA, Sass', image: kanbanImg, link: 'https://kanban-pwa.vercel.app/'},
        {title: 'Basic Store', description: 'Tech Stack: React, Redux, React Router v6, MUI', image: shopImg, link: 'https://react-redux-basic-shop.vercel.app/'},
        {title: 'GUI Calculator', description: 'Tech Stack: Angular, Desktop only', image: calcImg, link: 'https://gui-calculator-angular.herokuapp.com/'},
        {title: 'Drum Machine', description: 'Tech Stack: React, Desktop only', image: DrumMachineImg, link: 'https://codepen.io/Aziz_R/full/PojgmBa'},
        {title: 'D3 Bar Chart', description: 'Tech Stack: D3, Desktop only', image: D3Img, link: 'https://codepen.io/Aziz_R/full/eYGPYmy'},
        {title: 'Pomodoro Clock', description: 'Tech Stack: React, Desktop only', image: PomodoroImg, link: 'https://codepen.io/Aziz_R/full/JjyGgBV'},
        {title: 'Testing three.js', description: 'Tech Stack: Three.js, Desktop only', image: ThreeTestImg, link: 'https://codepen.io/Aziz_R/full/wvqRvvm'},
        {title: 'Random quote machine', description: 'Tech Stack: React, Desktop only', image: RandomQuotesImg, link: 'https://codepen.io/Aziz_R/project/full/DWzkvL'},
    ];

    return (
        <>
            <StyledCentered>
                <Typography variant="h2" sx={{paddingLeft: 3, paddingTop: 2, paddingBottom:0}} className='text'>
                    <Typewriter
                        onInit={(typewriter) => {
                            typewriter
                            .typeString('Portfolio')
                            .start();
                        }}
                    />
                </Typography>

                <StyledProjects>
                    <Grid container spacing={2} sx={{paddingRight: 1}}>
                        {
                            projects.map(item => {
                                return <ProjectItem
                                            title={item.title}
                                            description={item.description}
                                            link={item.link}
                                            image={item.image}
                                        />
                            })
                        }
                    </Grid>
                </StyledProjects>
            </StyledCentered>
        </>
    )
}
