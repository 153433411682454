import { styled } from "@mui/material"

export const StyledNavbar = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '60px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#eee1',
    color: '#fff',
    position: 'fixed'
}))

