import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Grid from '@mui/material/Grid';


export default function ProjectItem(props) {
  return (
    <Grid item xs={12} sm={4} xl={3}>
        <a href={props.link} target="_blank" rel="noreferrer">
            <Card sx={{ minWidth: 190,maxWidth: 345, mt: 5}}>
                <CardActionArea>
                    <CardMedia
                    component="img"
                    height="140"
                    image={props.image}
                    alt="green iguana"
                    />
                    <CardContent>
                    <Typography gutterBottom variant="h5" component="div" className='text'>
                        {props.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" className='text'>
                        {props.description}
                    </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </a>
    </Grid>
  );
}
