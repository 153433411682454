import { Typography } from "@mui/material";
import { StyledCentered, StyledAboutMe, StyledSocials } from "../components/styledComponents/Styled";
import Typewriter from 'typewriter-effect';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import { useEffect, useState } from "react";

import 'animate.css';

export default function Main() {
  const [showSocials, setShowsocials] = useState(false)
  const [skills] = useState({
    Javascript: {posX:( Math.random() * window.innerWidth) - 150, posY: (Math.random() * window.innerHeight) - 100},
    React: {posX:( Math.random() * window.innerWidth) - 150, posY: (Math.random() * window.innerHeight) - 100},
    NodeJS: {posX:( Math.random() * window.innerWidth) - 150, posY: (Math.random() * window.innerHeight) - 100},
    MySQL: {posX:( Math.random() * window.innerWidth) - 150, posY: (Math.random() * window.innerHeight) - 100},
    Laravel: {posX:( Math.random() * window.innerWidth) - 150, posY: (Math.random() * window.innerHeight) - 100},
    PHP: {posX:( Math.random() * window.innerWidth) - 150, posY: (Math.random() * window.innerHeight) - 100},
    Express: {posX:( Math.random() * window.innerWidth) - 150, posY: (Math.random() * window.innerHeight) - 100},
    MUI: {posX:( Math.random() * window.innerWidth) - 150, posY: (Math.random() * window.innerHeight) - 100},
    Typescript: {posX:( Math.random() * window.innerWidth) - 150, posY: (Math.random() * window.innerHeight) - 100},
    Git: {posX:( Math.random() * window.innerWidth) - 150, posY: (Math.random() * window.innerHeight) - 100},
    Docker: {posX:( Math.random() * window.innerWidth) - 150, posY: (Math.random() * window.innerHeight) - 100},
    Sass: {posX:( Math.random() * window.innerWidth) - 150, posY: (Math.random() * window.innerHeight) - 100},
    PWA: {posX:( Math.random() * window.innerWidth) - 150, posY: (Math.random() * window.innerHeight) - 100},
    CSS: {posX:( Math.random() * window.innerWidth) - 150, posY: (Math.random() * window.innerHeight) - 100},
    HTML: {posX:( Math.random() * window.innerWidth) - 150, posY: (Math.random() * window.innerHeight) - 100},
    XML: {posX:( Math.random() * window.innerWidth) - 150, posY: (Math.random() * window.innerHeight) - 100}
  });

  const socials = {
    email: 'info@arwd.de', linkedInUrl: "https://www.linkedin.com/in/aziz-r-a477339b/"
  }

  useEffect(() => {
    setTimeout(() => {
      setShowsocials(true)
    }, 11000)
  }, [])

  return (<>
    <StyledCentered>
      {
        Object.keys(skills).map(skill => {
          return <div style={{
            position: 'absolute',
            top: skills[skill].posY,
            left: skills[skill].posX,
            transform: "rotate(-30deg)",
          }}>
            <Typography className="text" sx={{
              fontSize: 42, mixBlendMode: "difference", color: '#090D13'
            }}>{skill}</Typography>
          </div>
        })
      }

      <Typography sx={{ display: 'block' }} variant="h2" className='text'>
        <Typewriter
          onInit={(typewriter) => {
            typewriter
              .deleteAll()
              .typeString('Hello')
              .pauseFor(10000)
              .deleteAll()
              .typeString('Welcome to my Portfolio!')
              .pauseFor(10000)
              .start();
          }}
        />
      </Typography>

      <StyledAboutMe>
        <Typography variant="h6" className='text'>
          <Typewriter
            options={{
              delay: 50
            }}
            onInit={(typewriter) => {
              typewriter
                .pauseFor(2500)
                .typeString('My name is Aziz Rajabov. I am currently looking for a job as a frontend developer in Germany. Feel free to contact with me!')
                .pauseFor(2500)
                .start();
            }}
          />
        </Typography>
      </StyledAboutMe>

      <StyledSocials
        style={showSocials ? { display: 'block' } : {}}
        className={showSocials ? "animate__animated animate__fadeInUp" : ""}
      >
        <a href={`mailto:${socials.email}`} target='_blank' rel="noreferrer" style={{ padding: 5, transition: '1s ease' }}>
          <EmailIcon
            className="bg-mix"
            sx={{
              color: '#fff', fontSize: 32
            }}
          />
        </a>

        <a href={socials.linkedInUrl} target='_blank' rel="noreferrer" style={{ padding: 5 }}>
          <LinkedInIcon
            className="bg-mix"
            sx={{
              color: '#fff', fontSize: 32
            }}
          />
        </a>
      </StyledSocials>

    </StyledCentered>
  </>)
}
