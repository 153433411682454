import { styled } from "@mui/material";
import { NavLink } from "react-router-dom";

export const StyledLink = styled(NavLink)(({theme}) => ({
    color: '#fff',
    margin: '10px'
}))

export const StyledCentered = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
    padding: '20px 100px 0',
    overflow: 'hidden'
}))

export const StyledAboutMe = styled('div')(({theme}) => ({
    marginTop: 30,
}))

export const StyledSocials = styled('div')(({theme}) => ({
    marginTop: 30,
    transition: '1s ease-in-out',
    display: 'none'
}))


export const StyledProjects = styled('div')(({theme}) => ({
    width: '100%',
    marginTop: 50,
    marginLeft: 50,
    paddingBottom: 150,
    overflow: 'auto'
}))
