import React, { useEffect, useRef, useState } from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { StyledPage } from './components/styledComponents/StyledPage';
import Main from './screens/Main';
import Portfolio from './screens/Portfolio';
import Navbar from './components/Navbar';
import { useMediaQuery } from '@mui/material';

const App = () => {
    const [x, setX] = useState(null)
    const [y, setY] = useState(null)

    const isMobile = useMediaQuery('(max-width: 900px)')
    const pageRef = useRef()

    useEffect(() => {
        document.addEventListener('mousemove',handleMouseMove)
        return function cleanup () {
            document.removeEventListener('mousemove',handleMouseMove)
        }
    }, [])

    useEffect(() => {
        if (!isMobile) {
            pageRef.current.style.background = `radial-gradient(circle at ${x}px ${y}px, rgb(79,20,204) 0%, rgba(79,20,204,0) calc(0% + 150px)) no-repeat border-box `
        } else {
            //TODO: gradient for mobile
        }
    }, [x, y, isMobile])

    const handleMouseMove = (ev) => {
        setX(ev.clientX)
        setY(ev.clientY)
    }

    return (
        <div>
        <BrowserRouter>
            <StyledPage ref={pageRef}>
            <Navbar />
            <Routes>
                <Route path="/" element={<Main />}/>
                <Route path="/portfolio" element={<Portfolio />}/>
            </Routes>
            </StyledPage>
        </BrowserRouter>
        </div>
    )
}

export default App
