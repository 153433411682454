import { useState } from 'react';
import {StyledNavbar} from './styledComponents/StyledNavbar'
import ModalDialogCV from './ModalDialogCV';
import { IconButton } from '@mui/material';

import CottageRoundedIcon from '@mui/icons-material/CottageRounded';
import AppsRoundedIcon from '@mui/icons-material/AppsRounded';
import ContactPageRoundedIcon from '@mui/icons-material/ContactPageRounded';

import { StyledLink } from './styledComponents/Styled';
import { Tooltip } from '@mui/material';

const NavbarLink = (props) => {
    return (
        <Tooltip title={props.title} placement='right'>
            <StyledLink to={props.to}>
                <IconButton>
                    {props.icon}
                </IconButton>
            </StyledLink>
        </Tooltip>
    )
}

const DownloadableNavbarLink = (props) => {
    const handleDownload = () => {
        props.onOpenCV()
    }

    return (
        <div style={{margin: 10}}>
            <Tooltip title={props.title} placement='right'>
                <IconButton onClick={handleDownload} className="bg-mix" >
                    {props.icon}
                </IconButton>
            </Tooltip>
        </div>
    )
}

export default function Navbar() {
    const [openCV, setOpenCV] = useState(false);

    const handleOpenCV = () => {
        setOpenCV(true);
    };

    const handleCloseCV = () => {
        setOpenCV(false);
    };

    return (
        <>
            <StyledNavbar>
                <NavbarLink 
                    to="/" 
                    title="Home" 
                    icon={<CottageRoundedIcon/>}
                />

                <NavbarLink 
                    to="/portfolio" 
                    title="Portfolio" 
                    icon={<AppsRoundedIcon />}
                />

                <DownloadableNavbarLink
                    file="" 
                    title="My CV" 
                    icon={<ContactPageRoundedIcon/>} 
                    onOpenCV={handleOpenCV}
                    
                />
            </StyledNavbar>

            <ModalDialogCV isOpen={openCV} onModalClose={handleCloseCV}/>
        </>
    )
}